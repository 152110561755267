import { FaTrashAlt } from "react-icons/fa";
const LineItem = ({ item, handleCheck, handleDelete, handleItemName }) => {
  const activateInput = (e) => {
    e.target.readOnly = false;
    e.target.style.backgroundColor = "#fff";
  };
  const deactivateInput = (e) => {
    e.target.readOnly = true;
    e.target.style.backgroundColor = "#eee";
  };
  return (
    <li className="item">
      <input
        type="checkbox"
        onChange={() => handleCheck(item._id)}
        checked={item.checked}
      />
      <input
        type="text"
        className="item"
        style={item.checked ? { textDecoration: "line-through" } : null}
        onDoubleClick={() => handleCheck(item._id)}
        value={item.item}
        readOnly={true}
        onFocus={activateInput}
        onBlur={deactivateInput}
        onChange={(e) => handleItemName(item._id, e.target.value)}
      />
      <FaTrashAlt
        onClick={() => handleDelete(item._id)}
        role="button"
        tabIndex="0"
        aria-label={`Delete ${item.item}`}
      />
    </li>
  );
};

export default LineItem;
