import React from "react";

const ReleaseCard = ({ myRelease }) => {
  const getLocaleReleaseDate = (releaseDate, locale) =>
    new Date(releaseDate).toLocaleDateString(locale);
  return (
    <div
      className="card"
      style={{
        width: "24rem",
        minHeight: "44rem",
      }}
    >
      <img
        className="card-img-top"
        src={
          typeof myRelease.images === "undefined"
            ? null
            : myRelease.images[0].uri
        }
        alt={myRelease.artists[0].name + " - " + myRelease.title}
        style={{ height: "24rem" }}
      />
      <div className="card-body">
        <h5 className="card-title" style={{ fontSize: "1rem" }}>
          <strong>{myRelease.title.substring(0, 39)}</strong>
        </h5>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            Artist: {myRelease.artists[0].name}
          </li>
          <li className="list-group-item">Label: {myRelease.labels[0].name}</li>
          <li className="list-group-item">
            Release Year: {getLocaleReleaseDate(myRelease.released, "de-DE")}
          </li>
          <li className="list-group-item">
            Format: {myRelease.formats[0].name}
          </li>
          <li className="list-group-item">Master ID: {myRelease.master_id}</li>
          <li className="list-group-item">Database ID: {myRelease._id.$oid}</li>
          <li className="list-group-item">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id={"heading" + myRelease._id.$oid}
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapse" + myRelease._id.$oid}
                    aria-expanded="false"
                    aria-controls={"#collapse" + myRelease._id.$oid}
                  >
                    TRACKS
                  </button>
                </h2>
                <div
                  id={"collapse" + myRelease._id.$oid}
                  className="accordion-collapse collapse"
                  aria-labelledby={"heading" + myRelease._id.$oid}
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      {myRelease.tracklist.map((track, index) => (
                        <li key={index} className="list-group-item">
                          {track.position ? track.position + " " : null}
                          {/* if (track.extraartists.length !==)
                    {track.extraartists.map(
                      (extraartist) => extraartist["name"]
                    )} */}
                          {"extraartists" in track
                            ? track.extraartists[0].name + " - "
                            : null}
                          {"artists" in track
                            ? track.artists[0].name + " - "
                            : null}
                          {track.title} {track.duration}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <a
          href={myRelease.uri}
          target="_blank"
          rel="noreferrer"
          className="btn btn-outline-success"
        >
          DISCOGS
        </a>
      </div>
    </div>
  );
};

export default ReleaseCard;
