import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./layouts/Header";
import Home from "./layouts/Home";
import Navigation from "./layouts/Navigation";
import SearchDiscogsCollection from "./layouts/SearchDiscogsCollection";
import ToDoList from "./todolist/ToDoList";

function App() {
  return (
    <div className="app">
      {<Header title="***TRANSMITTER***" />}
      <nav>
        <Navigation />
      </nav>
      <main>
        <Home />
        <Routes>
          <Route
            path="/searchmydiscogscollection"
            element={<SearchDiscogsCollection />}
          />
          <Route path="/todolist" element={<ToDoList />} />
        </Routes>
      </main>
      <footer>unestablished since 2022</footer>
    </div>
  );
}

export default App;
