import { useState, useEffect } from "react";

import AddItem from "./AddItem";
import SearchItem from "./SearchItem";
import ItemList from "./ItemList";

import apiRequest from "../helper/apiRequest";
import { API_URL } from "../constants/constants";
import { DATABASE_URL } from "../constants/constants";
import { DATABASE_URL_PHP } from "../constants/constants";

const ToDoList = () => {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [search, setSearch] = useState("");
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //add Item to Todolist

  const addItem = async (item) => {
    const myNewItem = { checked: false, item };
    const listItems = [...items, myNewItem];
    setItems(listItems);

    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(myNewItem),
    };
    //const result = await apiRequest(`${DATABASE_URL}todolist`, postOptions);
    const result = await apiRequest(
      `${DATABASE_URL_PHP}todolist/add`,
      postOptions
    );
    if (result) setFetchError(result);
  };

  // Update Todolist: is the item done or undone

  const handleCheck = async (todolistId) => {
    const listItems = items.map((item) =>
      item._id === todolistId
        ? {
            ...item,
            checked: !item.checked,
          }
        : item
    );
    setItems(listItems);

    const myItem = listItems.filter((item) => item._id === todolistId);
    const updateOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        checked: myItem[0].checked,
      }),
    };

    /***
     * In MongoDB is the _id an object, to send the id via get parameter to php extract the id value from the object
     * question: why is the value of the id in the key "$oid" and not in "_id"?
     ***/

    const itemId = todolistId["$oid"];

    const reqUrl = `${DATABASE_URL_PHP}todolist/edit/checked?id=${itemId}`;
    const result = await apiRequest(reqUrl, updateOptions);
    if (result) setFetchError(result);
  };

  // update TodoList: change Itemname

  const handleItemName = async (todolistId, name) => {
    const listItems = items.map((item) =>
      item._id === todolistId
        ? {
            ...item,
            item: name,
          }
        : item
    );
    setItems(listItems);

    const myItem = listItems.filter((item) => item._id === todolistId);

    const updateOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        item: myItem[0].item,
      }),
    };

    /***
     * In MongoDB is the _id an object, to send the id via get parameter to php extract the id value from the object
     * question: why is the value of the id in the key "$oid" and not in "_id"?
     ***/
    const itemId = todolistId["$oid"];

    const reqUrl = `${DATABASE_URL_PHP}todolist/edit/itemname?id=${itemId}`;
    const result = await apiRequest(reqUrl, updateOptions);
    if (result) setFetchError(result);
  };

  // Delete Item from TodoList

  const handleDelete = async (todolistId) => {
    const listItems = items.filter((item) => item._id !== todolistId);
    setItems(listItems);

    const deleteOptions = { method: "DELETE" };

    /***
     * In MongoDB is the _id an object, to send the id via get parameter to php extract the id value from the object
     * question: why is the value of the id in the key "$oid" and not in "_id"?
     ***/
    const itemId = todolistId["$oid"];

    const reqUrl = `${DATABASE_URL_PHP}todolist/delete?id=${itemId}`;
    const result = await apiRequest(reqUrl, deleteOptions);
    if (result) setFetchError(result);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newItem) return;
    addItem(newItem);
    setNewItem("");
  };

  // Get all Items from TodoList

  const fetchToDoListItems = async () => {
    try {
      //const response = await fetch(`${DATABASE_URL}todolist`);
      const response = await fetch(`${DATABASE_URL_PHP}todolist`);
      if (!response.ok) throw Error("Did not receive ToDo-List data");
      const listItems = await response.json();
      setItems(listItems);
      setFetchError(null);
    } catch (err) {
      setFetchError(err.message);
    } finally {
      //setIsTimetunnelLoading(false);
    }
  };

  useEffect(() => {
    fetchToDoListItems();
  }, []);

  return (
    <>
      <AddItem
        newItem={newItem}
        setNewItem={setNewItem}
        handleSubmit={handleSubmit}
      />
      <SearchItem search={search} setSearch={setSearch} />
      <div>
        {items.length ? (
          <ItemList
            handleCheck={handleCheck}
            handleDelete={handleDelete}
            handleItemName={handleItemName}
            items={items.filter((item) =>
              item.item.toLowerCase().includes(search.toLowerCase())
            )}
          />
        ) : (
          <p style={{ marginTop: "2rem" }}>Your list is empty.</p>
        )}
      </div>
    </>
  );
};

export default ToDoList;
