import React, { useState } from "react";

import SearchMyCollection from "./SearchMyCollection";
import ReleaseCard from "./ReleaseCard";

const SearchDiscogsCollection = () => {
  const [myReleases, setMyReleases] = useState();

  return (
    <>
      <SearchMyCollection setMyReleases={setMyReleases} />

      {myReleases && (
        <>
          <h2 style={{ marginTop: "1rem" }}>Suchergebnisse</h2>
          <h3 style={{ margin: "1rem 0" }}>
            Anzahl Treffer: {myReleases.length}
          </h3>
        </>
      )}
      {myReleases && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2.5rem",
          }}
        >
          {myReleases.map((myRelease) => (
            <article key={myRelease._id}>
              <ReleaseCard myRelease={myRelease} />
            </article>
          ))}
        </div>
      )}
    </>
  );
};

export default SearchDiscogsCollection;
