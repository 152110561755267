// transmitter playlist

export const DATABASE_URL = "https://transmitter-search.cyclic.app/";

export const DATABASE_URL_PHP = "https://schallarchiv.tristero.de/api/";

export const DEFAULT_TRACKS = 35;

// discogs
export const DISCOGS_URL_API = "https://api.discogs.com";
export const DISCOGS_URL = "https://discogs.com";

// todo list
//dev
export const API_URL = "http://localhost:3500/todolist";
//production
//export const API_URL = "https://transmitter.netlify.app"
