import React, { useState } from "react";
import Button from "./Button";
import { DATABASE_URL } from "../constants/constants";
import { DATABASE_URL_PHP } from "../constants/constants";

const SearchMyCollection = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [submitId, setSubmitId] = useState(0);

  // search artist or release-title or tracktitle
  const searchCollection = async (event) => {
    event.preventDefault();
    if (searchTerm === "") return;
    console.log(searchTerm);
    const response = await fetch(
      `${DATABASE_URL_PHP}mycollection/search?searchterm=${searchTerm}`
    );
    if (!response.ok) throw Error("Did not receive Search data");
    const searchResults = await response.json();
    props.setMyReleases(await searchResults);
  };

  // search artist
  const searchArtistInMyCollection = async (event) => {
    event.preventDefault();
    if (searchTerm === "") return;
    const response = await fetch(
      `${DATABASE_URL_PHP}mycollection/search/artist?searchterm=${searchTerm}`
    );
    if (!response.ok) throw Error("Did not receive Search data");
    const searchResults = await response.json();
    props.setMyReleases(await searchResults);
  };

  // search release title
  const searchTitleInMyCollection = async (event) => {
    event.preventDefault();
    if (searchTerm === "") return;
    const response = await fetch(
      `${DATABASE_URL_PHP}mycollection/search/releasetitle?searchterm=${searchTerm}`
    );
    if (!response.ok) throw Error("Did not receive Search data");
    const searchResults = await response.json();
    props.setMyReleases(await searchResults);
  };

  // search tracktitle
  const searchTrackTitleInMyCollection = async (event) => {
    event.preventDefault();
    if (searchTerm === "") return;
    const response = await fetch(
      `${DATABASE_URL_PHP}mycollection/search/tracktitle?searchterm=${searchTerm}`
    );
    if (!response.ok) throw Error("Did not receive Search data");
    const searchResults = await response.json();
    props.setMyReleases(await searchResults);
  };

  // search label
  const searchLabelInMyCollection = async (event) => {
    event.preventDefault();
    if (searchTerm === "") return;
    const response = await fetch(
      `${DATABASE_URL_PHP}mycollection/search/label?searchterm=${searchTerm}`
    );
    if (!response.ok) throw Error("Did not receive Search data");
    const searchResults = await response.json();
    props.setMyReleases(await searchResults);
  };

  // search style/genre
  const searchStyleInMyCollection = async (event) => {
    event.preventDefault();
    if (searchTerm === "") return;
    const response = await fetch(
      `${DATABASE_URL_PHP}mycollection/search/style?searchterm=${searchTerm}`
    );
    if (!response.ok) throw Error("Did not receive Search data");
    const searchResults = await response.json();
    props.setMyReleases(await searchResults);
  };

  // search release year
  const searchReleaseYearInMyCollection = async (event) => {
    event.preventDefault();
    if (setSearchTerm === "") return;
    const response = await fetch(
      `${DATABASE_URL_PHP}mycollection/search/releaseyear?searchterm=${searchTerm}`
    );
    if (!response.ok) throw Error("Did not receive Search data");
    const searchResults = await response.json();
    props.setMyReleases(await searchResults);
  };

  // search duration of track
  const searchDurationInMyCollection = async (event) => {
    event.preventDefault();
    if (searchTerm === "") return;
    const response = await fetch(
      `${DATABASE_URL_PHP}mycollection/search/duration?searchterm=${searchTerm}`
    );
    if (!response.ok) throw Error("Did not receive Search data");
    const searchResults = await response.json();
    props.setMyReleases(await searchResults);
  };

  /**
   * Array with all search functions for search select field options
   */
  const submitFunctions = [
    { submitFunction: searchCollection, label: "Artist/Release/Title" },
    { submitFunction: searchArtistInMyCollection, label: "Artist" },
    { submitFunction: searchTitleInMyCollection, label: "Release Title" },
    { submitFunction: searchTrackTitleInMyCollection, label: "Track Title" },
    { submitFunction: searchLabelInMyCollection, label: "Label" },
    { submitFunction: searchStyleInMyCollection, label: "Genre/Style" },
    { submitFunction: searchReleaseYearInMyCollection, label: "Release Year" },
    { submitFunction: searchDurationInMyCollection, label: "Duration" },
  ];

  return (
    <div>
      <h2>Search My Collection</h2>
      <form
        className="row"
        onSubmit={submitFunctions[submitId].submitFunction}
        style={{ display: "flex", gap: "2rem" }}
      >
        <div className="input-group col-10  col-md-4 ">
          <input
            className="form-control"
            type="search"
            value={searchTerm}
            placeholder="SEARCH TERM"
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
          />
        </div>
        <div className="col-10  col-md-4">
          <select
            className="form-select col-10  col-md-4 form-space"
            onChange={(e) => setSubmitId(Number(e.target.value))}
            value={submitId}
          >
            {submitFunctions.map((option, index) => (
              <option key={index} value={index}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-10  col-md-4">
          <Button
            style={{
              display: "block",
              color: "white",
              backgroundColor: "#000",
              padding: "0.5rem",
              marginLeft: "1rem",
            }}
            buttonText="Search Collection"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default SearchMyCollection;
