import React from "react"

const Button = (props) => {
  return (
    <button className="btn btn-outline-success" onClick={props.handle}>
      {props.buttonText}
    </button>
  )
}

export default Button
