import LineItem from "./LineItem";

const ItemList = ({ items, handleCheck, handleDelete, handleItemName }) => {
  return (
    <ul>
      {items.map((item) => (
        <LineItem
          key={item._id}
          item={item}
          handleCheck={handleCheck}
          handleDelete={handleDelete}
          handleItemName={handleItemName}
        />
      ))}
    </ul>
  );
};

export default ItemList;
